import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router'
import { Observable } from 'rxjs'
import { Injectable } from '@angular/core'
import { AuthService } from './auth.service'
import { CommonService } from './common.service'
import { PageConfigurationService } from './page-configuration.service'

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private auth: AuthService,
    private common: CommonService,
    private config: PageConfigurationService
  ) { }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.auth.isAuthenticated()) {
      return true
    } else {
      this.config.previousUrl.push('/' + route.url.toString())
      this.common.flashMessage('warning', 'warning', 'notAuthorized')
      this.auth.logout()
      this.router.navigateByUrl('auth/login')
    }
  }
}
