import { TuiRootModule, TuiDialogModule } from '@taiga-ui/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { AngularFireModule } from '@angular/fire/compat';
import {
  TUI_LANGUAGE,
  TUI_RUSSIAN_LANGUAGE,
  TUI_ENGLISH_LANGUAGE,
} from '@taiga-ui/i18n';
import { of } from 'rxjs';
import { MatDialogModule } from '@angular/material/dialog';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { Router, NavigationStart } from '@angular/router';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';

import { environment } from 'src/environments/environment';

import { AppComponent } from './app.component';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient],
      },
    }),
    MatPasswordStrengthModule.forRoot(),
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
    TuiRootModule,
    TuiDialogModule,
    MatDialogModule,
    NgxIntlTelInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
  ],
  providers: [
    {
      provide: TUI_LANGUAGE,
      useFactory: function (translateService: TranslateService) {
        if (translateService.defaultLang === 'ru') {
          return of(TUI_RUSSIAN_LANGUAGE);
        } else {
          return of(TUI_ENGLISH_LANGUAGE);
        }
      },
      deps: [TranslateService],
    },
  ],
  exports: [TranslateModule],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private router: Router) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (event.url.includes('redirect'))
          sessionStorage.setItem('redirect', event.url);
      }
    });
  }
}

export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
