import { Injectable, OnDestroy } from '@angular/core'
import { Router } from '@angular/router'
import { IUserItem } from '../interfaces'
import { Subscription } from 'rxjs'
import { first, take } from 'rxjs/operators'

import { CommonService } from './common.service'
import { HttpRequestsService } from './http-requests.service'
import { PageConfigurationService } from './page-configuration.service'

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnDestroy {

  allSubscription: Subscription[] = []
  previousUrl: string[] = this.config.previousUrl

  private get isAuth(): boolean {
    const token = this.token
    if (!token) {
      return false
    } else {
      if (token.expires_at) {
        return new Date() < new Date(token.expires_at.replace(/-/g, '/'))
      } else {
        return false
      }
    }
  }

  get token() {
    return JSON.parse(localStorage.getItem('token'))
  }

  set token(value) {
    window.localStorage.setItem('token', JSON.stringify(value))
  }

  constructor(
    private common: CommonService,
    private HttpService: HttpRequestsService,
    private config: PageConfigurationService,
    private router: Router
  ) { }

  hasPermissions(names: string[]): Promise<boolean> {
    return new Promise(resolve => {
      const HttpServiced = this.HttpService.get('has-permissions', null, '?permissions=' + JSON.stringify(names)).pipe(first()).subscribe((response: any) => {
        if (response?.allowed === true) {
          resolve(true)
        } else {
          resolve(false)
        }
      }, () => {
        resolve(false)
      })
      this.allSubscription.push(HttpServiced)
    })
  }

  login(credentials: { email: string; password: string; remember_me: boolean }) {
    this.config.setSubmitting('auth/login', true)
    const login = this.HttpService.login(credentials).pipe(first()).subscribe((response: any) => {
      if (response instanceof Object) {
        this.token = response
        this.config.setLoading('users', true)
        this.config.setLoading('get-user-progress', true)
        this.getUser(
          () => this.config.setLoading('users', false),
          () => this.config.setLoading('users', false)
        ).finally()
      } else {
        this.common.flashMessage('error', 'error', 'unexpected')
      }
      this.config.setSubmitting('auth/login', false)
    }, (error: any) => {
      this.config.setSubmitting('auth/login', false)
      this.common.flashMessage('error', 'error', error)
    })
    this.allSubscription.push(login)
  }

  socialAuth(user: IUserItem) {
    this.config.setSubmitting('auth/login', true)
    const socialAuth = this.HttpService.socialAuth(user).pipe(first()).subscribe((response: any) => {
      if (response instanceof Object) {
        this.token = response
        // this.getUser(user.email);
        this.config.setLoading('users', true)
        this.config.setLoading('get-user-progress', true)
        this.getUser(
          () => this.config.setLoading('users', false),
          () => this.config.setLoading('users', false)
        ).finally()
      } else {
        this.common.flashMessage('error', 'error', 'unexpected')
      }
      this.config.setSubmitting('auth/login', false)
    }, (error: any) => {
      this.config.setSubmitting('auth/login', false)
      this.common.flashMessage('error', 'error', error)
    })
    this.allSubscription.push(socialAuth)
  }

  getUser(successCallback: () => void = null, errorCallback: () => void = null, response: IUserItem = null): Promise<IUserItem> {
    return new Promise(resolve => {
      if (response !== null) {
        this.config.user = response
        this.config.user$ = new Promise(resolve1 => {
          resolve1(response)
        })
        if (successCallback !== null) {
          successCallback()
        }
        resolve(response)
      } else {
        const get = this.HttpService.get('users', -1).pipe(first()).subscribe((user: any) => {
          this.config.user = user
          this.config.user$ = new Promise(resolve1 => resolve1(user))
          if (successCallback !== null) successCallback()
          resolve(user)
        }, () => {
          if (errorCallback !== null) errorCallback()
          resolve(null)
        })
        this.allSubscription.push(get)
      }
    })
  }

  redirectBack() {
    this.previousUrl.pop()
    let redirectUrl = '/'
    this.previousUrl.reverse().some(route => {
      if (!route.startsWith('/auth')) {
        redirectUrl = route
        return true
      }
      return false
    })
    this.router.navigateByUrl(redirectUrl)
    this.previousUrl = []
  }

  logout() {
    if (!this.token) {
      this.cleanState()
      return
    }
    this.config.setSubmitting('auth/logout', true)
    const logout = this.HttpService.logout().pipe(take(1)).subscribe(() => {
      this.cleanState()
      this.config.setSubmitting('auth/logout', false)
    }, () => {
      this.config.setSubmitting('auth/logout', false)
    })
    this.allSubscription.push(logout)
  }

  cleanState() {
    localStorage.removeItem('token')
    this.config.user = null
    this.config.user$ = null
    this.forcedRunAuthGuard()
  }

  isAuthenticated(): boolean {
    return this.isAuth
  }

  verify(token: { token: string }) {
    const verify = this.HttpService.verify(token).pipe(first()).subscribe(response => {
      this.common.flashMessage('success', 'success', 'Your email has been verified successfully')
      this.router.navigate([`/auth/login`], { queryParams: { email: response.email } })
    }, (error: any) => {
      this.common.flashMessage('error', 'error', error.error.message)
    })
    this.allSubscription.push(verify)
  }

  forgotPassword(email: { email: string }) {
    const forgotPassword = this.HttpService.forgotPassword(email).pipe(first()).subscribe((res: any) => {
      this.common.flashMessage('info', 'success', res.message)
      this.router.navigate(['/'])
    }, (error: any) => {
      this.common.flashMessage('error', 'error', error.error.message)
    })
    this.allSubscription.push(forgotPassword)
  }

  forcedRunAuthGuard() {
    if (this.router.url.startsWith('/account') || this.router.url.startsWith('/test')) this.router.navigateByUrl('/auth/login')
  }

  ngOnDestroy(): void {
    this.allSubscription.forEach(sub => {
      if (sub) sub.unsubscribe()
    })
  }
}
