import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Observable } from 'rxjs'

import { environment } from 'src/environments/environment'

import { AKVS } from '../../main/forms/airline/interfaces/airline.interface'
import { StatesInterface } from '../interfaces/states.interface'

@Injectable({
  providedIn: 'root'
})
export class HttpRequestsService {

  private domain = environment.apiDomain;

  private get token() {
    return JSON.parse(localStorage.getItem('token'))?.access_token
  }

  get headers(): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      Authorization: 'Bearer ' + this.token
    })
  }

  constructor(private http: HttpClient) {}

  login(body: { email: string; password: string; remember_me: boolean }): Observable<object> {
    return this.http.post(`${this.domain}/auth/login`, body)
  }

  socialAuth(body: any): Observable<object> {
    return this.http.post(`${this.domain}/auth/social_login`, body)
  }

  logout(): Observable<object> {
    return this.http.get(`${this.domain}/auth/logout`, { headers: this.headers })
  }

  register(body: { email: string; password: string; password_confirmation: string }): Observable<object> {
    return this.http.post(`${this.domain}/auth/register`, body)
  }

  verify(body: { token: string }): Observable<any> {
    return this.http.get(`${this.domain}/auth/register/${body.token}`)
  }

  verifyThoFactor(body: { uuid: any, token: string }): Observable<any> {
    return this.http.get(`${this.domain}/auth/login/${body.uuid}/${body.token}`)
  }

  forgotPassword(body: { email: string }): Observable<object> {
    return this.http.post(`${this.domain}/auth/password/email`, body)
  }

  forgotPasswordVerify(body: { token: string }): Observable<any> {
    return this.http.get(`${this.domain}/auth/password/email/${body.token}`)
  }

  updatePassword(body: { email: string, password: string, password_confirmation: string }): Observable<object> {
    return this.http.post(`${this.domain}/auth/password/reset`, body)
  }

  getAll(route: string): Observable<object> {
    return this.http.get(`${this.domain}/${route}`, { headers: this.headers })
  }

  get(route: string, id: any = null, query: string = ''): Observable<object> {
    return this.http.get(`${this.domain}/${route}/${id}${query}`, { headers: this.headers })
  }

  searchFilter(route: string) {
    return this.http.get<any>(`${this.domain}/${route}`, { headers: this.headers })
  }

  createFilter(route: string, body: any) {
    const header = new HttpHeaders({ 'Content-Type': 'application/json', Authorization: 'Bearer ' + this.token })
    return this.http.post(`${this.domain}/${route}`, body, { headers: header })
  }

  editFilter(route: string, body: any) {
    const header = new HttpHeaders({ 'Content-Type': 'application/json', Authorization: 'Bearer ' + this.token })
    return this.http.post(`${this.domain}/${route}`, body, { headers: header })
  }

  getAllFilters(route: string) {
    return this.http.get(`${this.domain}/${route}`, { headers: this.headers })
  }

  changeStatus(route: string, body: any) {
    return this.http.post(`${this.domain}/${route}`, body, { headers: this.headers })
  }

  getRoles(route: string) {
    return this.http.get(`${this.domain}/${route}`, { headers: this.headers })
  }

  archivedNForm(route: string, body: any) {
    return this.http.post(`${this.domain}/${route}`, body, { headers: this.headers })
  }

  duplication(route: string) {
    return this.http.get(`${this.domain}/${route}`, { headers: this.headers })
  }

  checkAno(route: string) {
    return this.http.get(`${this.domain}/${route}`, { headers: this.headers })
  }

  getCategoryTypes(route: string) {
    return this.http.get(`${this.domain}/${route}`, { headers: this.headers })
  }

  addToFavorites(route: string) {
    return this.http.get(`${this.domain}/${route}`, { headers: this.headers })
  }

  removeFromFavorites(route: string) {
    return this.http.get(`${this.domain}/${route}`, { headers: this.headers })
  }

  getTableColumns(route: any) {
    const header = new HttpHeaders({ 'Content-Type': 'application/json', Authorization: 'Bearer ' + this.token })
    return this.http.get(`${this.domain}/${route}`, { headers: header })
  }

  changeTableColumns(route: string, body: any) {
    const header = new HttpHeaders({ 'Content-Type': 'application/json', Authorization: 'Bearer ' + this.token })
    return this.http.post(`${this.domain}/${route}`, body, { headers: header })
  }

  getSiteLang(route: string) {
    return this.http.get(`${this.domain}/${route}`, { headers: this.headers })
  }

  getStates(): Observable<StatesInterface[]> {
    const route = `/v1/GetDirectory?name=States`
    return this.http.get<StatesInterface[]>(this.domain + route)
  }

  getDir(url: string): Observable<any> {
    return this.http.get(url, { headers: this.headers })
  }

  getAKVSByID(url: string) {
    return this.http.get<AKVS>(url, { headers: this.headers })
  }

  postRoute(url: string, data: any) {
    return this.http.post<any>(url, data, { headers: this.headers })
  }

  changeRetryRegistrySort(route: string, body: any) {
    const header = new HttpHeaders({ 'Content-Type': 'application/json', Authorization: 'Bearer ' + this.token })
    return this.http.post(`${this.domain}/${route}`, body, { headers: header })
  }
}
