import { Injectable } from '@angular/core';
import {AbstractControl, FormArray, FormControl, FormGroup} from '@angular/forms';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CommonService {

  headerBackground$ = new Subject<boolean>();
  messageTitles = {
    stored: 'Data has been added',
    updated: 'Data has been updated',
    deleted: 'Data has been deleted',
    error: 'Error occured',
    info: 'Information',
    wait: 'Wait, please',
    warning: 'Warning',
    performed: 'Performed',
    notPerformed: 'Not performed'
  };

  messageTexts = {
    unexpected: 'Unexpected server response',
    noChanges: 'No changes were made',
    formInvalid: 'To continue, fill all required fields',
    notAuthorized: 'Authorization is required to access this resource. Please, log in.'
  };

  digitNames: string[] = ['','one','two','three','four','five','six','seven','eight','nine','ten', 'eleven','twelve','thirteen','fourteen','fifteen','sixteen','seventeen','eighteen','nineteen'];

  constructor() {}

  flashMessage(type: string, templateTitle: string, msg: any = null) {
    // if (msg instanceof Object) {
    //   if (msg.error?.errors !== undefined) {
    //     for (const [key, value] of Object.entries(msg.error.errors)) {
    //       this.toast[type](
    //         value[0],
    //         this.messageTitles[templateTitle]
    //       );
    //     }
    //   }  else if (msg.error?.message !== undefined) {
    //     this.toast[type](
    //       msg.error.message,
    //       this.messageTitles[templateTitle]
    //     );
    //   } else if (msg.statusText !== undefined) {
    //     this.toast[type](
    //       msg.statusText,
    //       this.messageTitles[templateTitle]
    //     );
    //   } else if (msg.message !== undefined) {
    //     const currentOptions: string[] = [];
    //     if (msg.message.title !== undefined) {
    //       currentOptions[1] = msg.message.title;
    //     }
    //     if (msg.message.text !== undefined) {
    //       currentOptions[0] = msg.message.text;
    //     }
    //
    //     if (currentOptions.length !== 0) {
    //       this.toast[type](currentOptions[0], currentOptions[1]);
    //     } else {
    //       this.toast[type](null, this.messageTitles[templateTitle]);
    //     }
    //   } else {
    //     this.toast[type](null, this.messageTitles[templateTitle]);
    //   }
    // } else {
    //   if (typeof msg === 'string') {
    //     this.toast[type](
    //       this.messageTexts[msg] ? this.messageTexts[msg] : msg,
    //       this.messageTitles[templateTitle]
    //     );
    //   } else {
    //     this.toast[type](null, this.messageTitles[templateTitle]);
    //   }
    // }
  }

  onCheckboxChange(form: FormGroup, controlName: string, event: any) {
    const array: FormArray = form.get(controlName) as FormArray;
    if (event.target.checked) {
      array.push(new FormControl(+event.target.value));
    } else {
      array.controls.forEach((item: AbstractControl, idx: number) => {
        // if (+item.value.id === +event.target.value) {
        //   array.removeAt(idx);
        // }
        if (+item.value === +event.target.value) {
          array.removeAt(idx);
        }
      });
    }
  }

  onNotReactiveCheckboxChange(array: any[], event: any) {
    if (event.target.checked) {
      array.push(event.target.value);
    } else {
      const idx = array.findIndex(el => el === event.target.value);
      array.splice(idx, 1);
    }
  }

  secondsToHms(seconds: number, withWords = false,
               twoDigits = true, units: string[] = ['h', 'm', 's']): string {
    seconds = Number(seconds);
    let h: any;
    let m: any;
    let s: any;

    if (twoDigits === true) {
      h = ('0' + Math.floor(seconds / 3600)).slice(-2);
      m = units.includes('h') ? ('0' + Math.floor(seconds % 3600 / 60)).slice(-2) : ('0' + Math.floor(seconds / 60)).slice(-2);
      s = units.includes('m') ? ('0' + Math.floor(seconds % 3600 % 60)).slice(-2) : ('0' + Math.floor(seconds)).slice(-2);
    } else {
      h = Math.floor(seconds / 3600);
      m = units.includes('h') ? Math.floor(seconds % 3600 / 60) : Math.floor(seconds / 60);
      s = units.includes('m') ? Math.floor(seconds % 3600 % 60) : Math.floor(seconds);
    }


    const result: string[] = [];
    if (withWords === true) {
      if (Number(h) !== 0 && units.includes('h')) { result.push(h + (h === 1 || h === '01' ? ' hour' : ' hours')); }
      if (Number(m) !== 0 && units.includes('m')) { result.push(m + ' min'); }
      if (Number(s) !== 0 && units.includes('s')) { result.push(s + ' sec'); }
      if (result.length === 0) { result.push('--'); }
    } else {
      if (units.includes('h')) { result.push(h); }
      if (units.includes('m')) { result.push(m); }
      if (units.includes('s')) { result.push(s); }
      return result.join(':');
    }

    return result.join(', ');
  }

  sortItems(items: any[], sortBy: string = null, sortDirection: string = 'asc'): any[] {
    if (sortBy === null || items.length < 1) { return items; }
    const sortedAllItems = items.sort((a, b) => {
      if (sortBy !== null && a && b) {
        if (sortDirection === 'asc') {
          return a[sortBy] > b[sortBy] ? 1 : -1;
        } else {
          return a[sortBy] > b[sortBy] ? -1 : 1;
        }
      }
      return 0;
    });
    return sortedAllItems;
  }

  scrollSmoothTo = (offset: number) => {
    window.scrollTo({top: offset, behavior: 'smooth'});
  }

  scrollSmoothIntoCenterOfView = (id: string, toBlockCenter = false, callback: () => void = null) => {
    const element = document.getElementById(id);
    if (!element) { return; }
    let elementOffset = element.getBoundingClientRect().top;
    if (toBlockCenter === true) {
      elementOffset += element.clientHeight / 2;
    }
    const offset = elementOffset + window.pageYOffset - (window.innerHeight / 2);
    window.scrollTo({top: offset, behavior: 'smooth'});
    const checkScroll = () => {
      if (Math.abs(offset - window.pageYOffset) < 10  ||
        Math.abs(document.body.scrollHeight - (window.innerHeight + window.scrollY)) < 10) {
        callback();
        window.removeEventListener('scroll', checkScroll);
      }
    };
    if (callback !== null) {
      window.addEventListener('scroll', checkScroll);
    }
  }

  scrollSmoothIntoTopOfView = (id: string, callback: () => void = null, topOffset: number = 0) => {
    const element = document.getElementById(id);
    if (!element) { return; }
    const offset = element.getBoundingClientRect().top + window.pageYOffset - topOffset;
    window.scrollTo({top: offset, behavior: 'smooth'});
    const checkScroll = () => {
      if (Math.abs(offset - window.pageYOffset) < 10  ||
        Math.abs(document.body.scrollHeight - (window.innerHeight + window.scrollY)) < 10) {
        callback();
        window.removeEventListener('scroll', checkScroll);
      }
    };
    if (callback !== null) {
      window.addEventListener('scroll', checkScroll);
    }
  }

  copyTextToClipboard(text: string) {
    const dummy = document.createElement('textarea');
    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    try {
      document.execCommand('copy');
      this.flashMessage('info', 'performed', 'Text has been copied to clipboard.');
    } catch (err) {
      console.error(err);
      this.flashMessage('warning', 'warning', 'Text can\'t be copied to clipboard.');
    }
    document.body.removeChild(dummy);
  }
}
