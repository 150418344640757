import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {
  ILoadingEvent,
  INavRoutes,
  ISettings,
  IUserItem
} from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class PageConfigurationService {
  siteSettings: ISettings[] = [];
  navRoutes: INavRoutes[] = [
    {route: '/map', label: 'header.map'},
    {route: '/rpl', label: 'header.flight_plans'},
    {route: '/forms', label: 'header.forms_r/n'},
    {route: '/aircrafts', label: 'header.aircrafts'},
    {route: '/restricted-zones', label: 'header.restricted_zones'},
    {route: '/groups', label: 'header.groups'},
    {route: '/help', label: 'header.help'}
  ];
  previousUrl: string[] = [];
  route = '';
  dimension: string;
  dimensionNum: number;
  isMobileDevice = false;
  isiOS = false;

  user$: Promise<IUserItem> = null;
  user: IUserItem = null;


  isLoading = false;
  isSubmitting = false;
  _loading: string[] = [];
  _prevLoading: string[] = [];
  _submitting: string[] = [];
  _prevSubmitting: string[] = [];
  get loading() {
    return this._loading;
  }
  get submitting() {
    return this._submitting;
  }

  onChangeRoute$: Subject<string> = new Subject<string>();
  onChangeLoading$: Subject<ILoadingEvent> = new Subject<ILoadingEvent>();
  onScrollPageTop$: Subject<boolean> = new Subject<boolean>();
  onWindowDimensionChanged$: Subject<number> = new Subject<number>();

  constructor() {}

  setLoading(name: string, value: boolean) {
    this._prevLoading = this._loading;
    if (value === true) {
      this._loading.push(name);
    } else {
      this._loading = this._loading.filter(item => item !== name);
    }
    this.isLoading = this._loading.length > 0;
    this.triggerChangeLoadingEvent();
  }

  setSubmitting(name: string, value: boolean) {
    this._prevSubmitting = this._submitting;
    if (value === true) {
      this._submitting.push(name);
    } else {
      this._submitting = this._submitting.filter(item => item !== name);
    }
    this.isSubmitting = this._submitting.length > 0;
    this.triggerChangeLoadingEvent();
  }

  private triggerChangeLoadingEvent() {
    this.onChangeLoading$.next({
      loading: this._loading,
      prevLoading: this._prevLoading,
      submitting: this._submitting,
      prevSubmitting: this._prevSubmitting
    });
  }

  getSettingsItem(field: string, needParse: boolean = false): any {
    const found = this.siteSettings.find(item => item.key === field);
    if (found && needParse) {
      return JSON.parse(found.value);
    } else if (found && !needParse) {
      return found.value;
    } else {
      return null;
    }
  }

}
